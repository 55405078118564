<template>
  <div>
    <topmenu :comname="'specimens'"></topmenu>
    <div class="widht1240">
      <div class="t-map">
        您当前的位置：
        <span>大会集锦</span>
      </div>
      <div class="widht200 fl">
        <leftmenulist :title="'大会集锦'" :keyid="id"></leftmenulist>
        <contact></contact>
      </div>
      <div class="widht1030 bg-white fr">
        <ul class="Collection_tab" v-if="id==1">
            <!-- <li @click="getCollectionList(5)" :class="CollectionNum==5?'on':''">视频集锦</li> -->
            <li v-for="(itemName,index) in navigationBar" @click="getCollectionList(itemName.id)" :class="CollectionNum==itemName.id?'on':''" :key="index">{{itemName.typeName}}</li>
            <!-- <li @click="getCollectionList(3)" :class="CollectionNum==3?'on':''">应急技术成果对接会</li>
            <li @click="getCollectionList(4)" :class="CollectionNum==4?'on':''">视频集锦</li> -->
          </ul>
        <div v-if="isshowlist" :class="id==1?'listinfor':'listinfor margin-top-20'" v-loading="loading">
          <ul>
            <li v-for="item in list" :key="item.id">            
             
               <video v-if="item.videoUrl!=null" controls  controlsList="nodownload"  :poster="item.imgUrl" :src="item.videoUrl"  ></video>
               <el-image 
               v-else
                :src="item.imgUrlMin?item.imgUrlMin:item.imgUrl"
                fit="fill"
                class="imgpur"
                style="width:480px; height:290px;"
                @click="openimgmore(item.imgUrl)"
              ></el-image>
              
              <!-- <el-button
                class="posetom"
                type="text"
                @click="opendialog(item.videoUrl,item.imgUrl)"
                v-if="item.videoUrl!=null"
              >
                <el-image :src="require('@/assets/images/shipin.png')" style="width:480px; height:290px;"></el-image>
              </el-button> -->
            </li>
            
          </ul>
          <el-pagination
            background
            layout="prev, pager, next"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-count="totalPage"
            :current-page="currentPage"
            :page-size="pagesize"
            :hide-on-single-page="true"
            class="t_page"
          ></el-pagination>
        </div>
          <nocont v-else></nocont>  
      </div>
    </div>
    <bottominfo></bottominfo>
    <el-dialog :title="sptitle" :visible.sync="dialogVisible" :before-close="handleClose">
      <span>
        <video controls  controlsList="nodownload" autoplay="autoplay" :poster="imgsrc" :src="spsrc" height="500" style="width: 100%;"></video>
      </span>
    </el-dialog>
    <el-dialog :title="imgtitle" :visible.sync="dialogVisible2" :before-close="handleClose" class="imgloading">
     <!-- <el-carousel height="660px" :autoplay="false"> -->
        <!-- <el-carousel-item v-for="(item,index) in listimg" :key="index"> -->
          <el-image :src="listimg" style="max-width:100%;max-height:100%;"></el-image>
          <!-- <img :src="item" style="max-width:100%;max-height:100%;width: 80%;height: 90%;" /> -->
        <!-- </el-carousel-item> -->
      <!-- </el-carousel> -->
    </el-dialog>
  </div>
</template>
<script>
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
import contact from "@/components/public/contact";
import nocont from "@/components/public/nocont";
import leftmenulist from "@/components/public/menulist";
export default {
  name: "specimens",
  data() {
    return {
      id:1,
      list: [],
      listimg: [],
      imgtitle: "",
      dialogVisible2: false,
      isshowlist: true,
      loading: true,
      currentPage: 1, //当前页
      pagesize: 6, //每页显示条数
      totalPage: 0, //总页数
      dialogVisible: false,
      sptitle: "",
      spsrc: "",
      imgsrc:'',
      navigationBar:[],
      CollectionNum:'',//展会集锦tab栏状态
    };
  },
  components: {
    topmenu,
    bottominfo,
    contact,
    nocont,
    leftmenulist
  },
 
  created() {
  
    if(this.$route.query.id){
        this.id = this.$route.query.id;
    }
 
      if(this.id==2){
        this.CollectionNum=5
         this.getinformation(this.CollectionNum);
      }else{
         this.getNavigationBar();
      }
     
  
  },
  watch: {
    //监听翻页
    currentPage: function(newVal, oldVal) {
      this.loading = true;
      this.getinformation(this.CollectionNum);
    },
     $route: function(to, from) {
       debugger
       if(this.$route.query.id){
          
          this.id = this.$route.query.id;
       }
    
      if(this.id==2){
        this.CollectionNum=5
        this.currentPage=1;
         this.getinformation(this.CollectionNum);
      }else{
          this.currentPage=1;
          this.getNavigationBar();
      }
     
    }
  },
  methods: {
    opendialog(src,imgsrc) {
      if(src==null){
        this.openimgmore(imgsrc)
      }else{
        this.dialogVisible = true;
        this.spsrc = src;
        this.imgsrc = imgsrc;
      }
    },
    // 展会集锦tab栏切换
    getCollectionList(num){
      this.CollectionNum = num
      this.currentPage = 1
      this.getinformation(this.CollectionNum)
    },
    openimgmore(src) {
      this.listimg = src;
      this.dialogVisible2 = true;
    },
    handleClose(done) {
      this.spsrc = ''
      done();
    },
    handleSizeChange: function(size) {
      //翻页
      this.pagesize = size;
    },
    handleCurrentChange: function(currentPage) {
      //翻页
      this.currentPage = currentPage;
    },
    getinformation(type) {
     
      let data = {
        current: this.currentPage,
        size: this.pagesize,
        isPage: true,
        iIspcshow:1,
        sort:3,
        type:type
      };
      this.$axios
        .post("/api/rally/getExhibitionCollectionPageList", data)
        // .post("/api/Applets/GetActivity")
        .then(res => {
          this.loading = false;
          this.totalPage = res[1].pages;
          console.log(res,"相对应栏目的图片")
          if (res[0].length > 0) {
            let infordata = res[0];
            for (let i = 0; i < infordata.length; i++) {
              // console.log(infordata[i].videoUrl)
                 infordata[i].vImgUrlfn = infordata[i].imgUrl;
            }
            this.list = infordata;
            this.isshowlist = true;
          } else {
            this.isshowlist = false;
          }
        });
        
    },
    getNavigationBar() {
      this.axios.post("/apiNavigationBar/Applets/GetActivity").then(res => {
          console.log(res.data.data,"新接口")
          this.navigationBar = res.data.data
          //  console.log(this.navigationBar)
          this.CollectionNum = this.navigationBar[0].id;
          this.getinformation(this.CollectionNum);
        })
    }
  }
};
</script>
<style scoped>
.listinfor {
  padding:20px 0 20px;
  overflow: hidden;
}
.listinfor ul {
  display: block;
  overflow: hidden;
}
.listinfor li {
  margin-left: 20px;
  margin-bottom: 30px;
  float: left;
  width: 480px;
  position: relative;
}
.posetom {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  padding: 0;
}
.listinfor li img {
  display: block;
  overflow: hidden;
}
.listinfor li video{
	width:480px;
	height:290px;
	object-fit: cover;
	border: 0px;
}
.listinfor li video:focus {
outline:none;
}
.listinfor li span {
  display: block;
  line-height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.t_page {
  margin-top: 30px;
}
.imgpur{
  cursor: pointer;
}
.imgloading .el-carousel__item{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.Collection_tab{
  padding-top: 40px;
  display: flex;
flex-wrap: wrap;
  justify-content:flex-start;
  width: 100%;
  /* margin-left: 20px; */
}
.Collection_tab li{
  cursor: pointer;
  padding: 0 15px;
  max-width: 160px;
  /* text-align: left; */
  /* margin-left: 30px; */
  /* text-align: center; */
  /* float: left; */
  /* margin-right: 10px; */
  font-size: 14px;
  float: left;
  /* position: relative; */
  overflow: hidden;
-webkit-line-clamp: 2;
line-clamp: 2;
-webkit-box-orient: vertical;
box-orient: vertical;
display: -webkit-box;
height: 48px;

  margin-bottom:10px;
}

.Collection_tab li.on{
  color: #073290;
  position: relative;
}
.Collection_tab li.on::after {
  position: absolute;
  content: "";
  left:50%;
  top:44px;
  width: 50px;
  height: 3px;
  background-color: #073290;
  margin-left: -25px;
}
.margin-top-20{
  margin-top:20px
}
</style>